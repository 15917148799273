import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { customFetch } from "./functions/customFetch";
import { isDev } from "app/helpers/functions/isDev";
import { withRetry } from "./functions/withRetry";
import { withTrimm } from "./functions/withTrimm";
import { getGlobalSecurityInstance } from "../views/pages/WidgetsPage/utils/security-util";

const getBaseUrlPrefix = () => {
    return window.self !== window.top ? "/widgets-api" : "/api";
}

export const getPortalApiBaseUrl = () => {
    const url = isDev() ? process.env.REACT_APP_HOST : window.location.origin;
    const prefix = getBaseUrlPrefix();

    return `${url}${prefix}`;
}

const portalApiQuery = fetchBaseQuery({
    baseUrl: `${getPortalApiBaseUrl()}`,
    prepareHeaders: (headers) => {
        if (window.self !== window.top) {
            const { accessToken } = getGlobalSecurityInstance();
            if (accessToken) {
                headers.set("Authorization", `Bearer ${accessToken}`);
                return headers;
            }
            return undefined
        }
        return undefined
    },
    credentials: "include",
    responseHandler: "content-type",
    fetchFn: customFetch,
    redirect: "follow"
})

export const PortalApi = createApi({
    reducerPath: "portalApi",
    tagTypes: ["customer-info", "subscriptions-list", "unpaid-invoices",
        "invoices-list", "scheduled-changes", "check-customer", "single-payments",
        "invoices-list-query", "payment-methods"],
    baseQuery: withTrimm(withRetry(portalApiQuery)),
    endpoints: () => ({})
})